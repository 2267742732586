.FindTeam {
  display: flex;
  flex-direction: row;

  input {
    flex: 1;
    margin-right: 1rem;
  }

  button {
    flex: 0.5;
  }
}

.ActivateTeam {
  display: flex;
  flex-direction: row;

  > div {
    flex: 1;
    display: flex;
    margin-right: 1rem;
    align-items: center;
    padding: 0.75rem 1.75rem 0.75rem 0;
    border: 1px solid transparent;
    font-family: var(--font-stack);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1rem;

    div {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 1rem;
      margin-right: 0.5rem;
    }
    span {
      flex: 1;

      em {
        font-size: 80%;
      }
    }
  }
  button {
    flex: 0.5;
  }
}

.Hero {
  h1 {
    font-weight: 700;
    font-size: var(--font-size-large);
  }
  p {
    margin: 1rem 0 0 0;
    font-size: var(--font-size-small);
  }
  ol {
    list-style-type: decimal;
    padding-left: 1.25rem;

    > li {
      margin-top: 0.25rem;
      font-size: var(--font-size-small);
    }
  }
  hr {
    margin: 1rem 0;
  }
  .ManageSubs {
    display: flex;
    flex: 1;
    justify-content: center;
  }
}

.Headline {
  font-weight: 700;
  font-size: calc(var(--font-size-large) - 40%);
  margin-left: 1rem;
  margin-bottom: 0.5rem;
}
