@import '../../../assets/mixins.scss';

.textarea {
  overflow: hidden;

  width: 100%;
  margin-top: 0.325rem;
  padding: 0;

  resize: none;
  transition: none;

  border: none;
  border-radius: 0;

  @include text-body;

  &:hover,
  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }

  &::placeholder {
    color: var(--color-black);

    font-weight: 400;
  }
}
