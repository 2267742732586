.alert {
  margin-bottom: 1rem;
  padding: 1rem;

  border-radius: 8px;

  font-size: 0.9rem;

  &--info {
    color: var(--color-info);
    background-color: var(--color-info-light);
  }

  &--negative {
    color: var(--color-negative);
    background-color: var(--color-negative-light);
  }

  &--positive {
    color: var(--color-positive);
    background-color: var(--color-positive-light);
  }
}
