@mixin reset-button {
  overflow: visible;

  width: auto;
  margin: 0;
  padding: 0;
  /* inherit font & color from ancestor */

  color: inherit;
  border: none;
  background: transparent;

  font: inherit;
  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;
  /* Corrects font smoothing for webkit */

  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  /* Remove excess padding and border in Firefox 4+ */
  &:-moz-focus-inner {
    padding: 0;

    border: 0;
  }
}

@mixin text-large {
  font-size: var(--font-size-large);
  font-weight: 500;
  line-height: 1.3;
}

@mixin text-body {
  font-size: var(--font-size-normal);
  font-weight: 400;
  line-height: 1.4;
}

@mixin text-small {
  color: var(--color-gray-dark);

  font-size: var(--font-size-small);
  font-weight: 400;
  line-height: 1.4;
}

@mixin text-caption {
  text-transform: uppercase;

  color: var(--color-gray-dark);

  font-size: var(--font-size-small);
  font-weight: 500;
  line-height: 1.5;
}
