.barBg {
  height: 16px;
  width: 100%;
  background-color: #eaeced;
  border-radius: 1rem;
  overflow: hidden;
}

.bar {
  height: 100%;
  background-color: var(--color-green);
  border-radius: 1rem;
}

.loading {
  .bar {
    background-color: var(--color-border-dark);
    animation: pulse 1s infinite;
  }
}

@keyframes pulse {
  0% {
    opacity: 0;
    width: 0;
  }
  100% {
    opacity: 0.75;
    width: 100%;
  }
}
