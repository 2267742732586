@import '../../../assets/mixins.scss';

.team {
  display: flex;
  align-items: center;

  margin-bottom: 1rem;
  padding-bottom: 1rem;

  border-bottom: 1px solid var(--color-border);
}

.listItem {
  > a {
    display: grid;

    margin-bottom: 0.25rem;

    font-weight: 500;

    grid-template-columns: auto 1fr auto;
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    font-size: 1rem;
  }
}

.wrapperTeam {
  overflow-y: auto;
}
.teamColorPill {
  width: 16px;
  height: 16px;
  margin-right: var(--size-spacing-mini);

  border-width: 1px;
  border-style: solid;
  border-radius: 100%;
  margin-top: 0.15rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: var(--size-spacing-small);

  h4 {
    @include text-caption;
  }
}

.headerNonProTeams {
  margin-top: var(--size-spacing-small);
  margin-left: var(--size-spacing-mini);
}

.upgradeWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2rem;
  padding: 0 20px;
  align-items: center;
}

.upgradeButton {
  background-color: #55469f;
  color: white;
  border-radius: 8px;
  display: block;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0.8rem 1.5rem;
}

.upgradeButton:hover {
  background-color: #55469f;
  opacity: 0.85;
  color: white;
}

.upgradeText {
  color: #888;
  font-size: 0.8rem;
  margin-top: 1rem;
  text-align: center;
  text-wrap: balance;
}

.emptyTeamList  {
  padding: 20px 10px 10px;
  text-align: center;
  text-wrap: balance;
  font-size: 0.9rem;
  color: #999;
}
