.wrapper {
  display: flex;
  align-items: center;

  margin-bottom: 0.25rem;
  padding: 0.5rem;

  border: 1px solid var(--color-border);
  border-radius: 0.25rem;

  > img {
    width: 20px;
    height: 28px;
    margin-right: calc(var(--size-spacing-large) / 4);
  }
}

.spin {
  margin-left: 0.5rem;
  animation: spinner 1s infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
