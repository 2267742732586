@import '../../assets/mixins.scss';

.wrapper {
}

.center {
  display: flex;
  justify-content: center;
}

.header {
  margin-bottom: var(--size-spacing-large);
  padding-bottom: var(--size-spacing-large);

  text-align: center;

  border-bottom: 1px solid var(--color-border);

  @include text-large;

  h1 {
    margin-top: var(--size-spacing-small);
  }
}

.title {
  display: flex;
  justify-content: center;

  div {
    margin-right: var(--size-spacing-mini);
  }
}

.connections {
  @include text-small;
}
