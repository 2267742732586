@import '../../assets/mixins.scss';

a.button:visited,
a.button:hover,
.button {
  display: flex;
  align-items: center;

  padding: 0.75rem 1rem;

  transition: all 300ms;

  color: var(--color-white);
  border-radius: 8px;
  background-color: var(--color-green);

  font-family: var(--font-stack);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1rem;

  &:hover {
    cursor: pointer;
    border-color: transparent;
    box-shadow: var(--box-shadow);
  }

  &:disabled {
    opacity: 0.5;
  }
}

.block {
  width: 100%;
}

.round {
  border-radius: 3rem;
}

.small {
  padding: 0.5rem 0.75rem;

  font-size: 0.875rem;
}

.inverted {
  color: var(--color-green);
  background-color: var(--color-white);
}

.destructive {
  background-color: var(--color-negative);
}

.reset {
  @include reset-button;

  &:hover {
    box-shadow: none;
  }
}

.spin {
  animation: spinner 1s infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
