.disabled {
  opacity: 0.5;
}

.label {
  text-transform: uppercase;
  font-size: 90%;
  font-weight: 500;
  color: #2c3948;
}

.input {
  &::placeholder {
    color: var(--color-gray);
  }
}
