.box {
  overflow-x: hidden;
  overflow-y: auto;

  margin-bottom: 1rem;

  border-radius: 0.5rem;
  background: var(--color-white);
  box-shadow: var(--box-shadow);
}

.noPadding {
  padding: 0;
}

.title {
  display: flex;
  justify-content: center;

  padding: 1rem;

  text-transform: uppercase;

  border-bottom: 1px solid var(--color-border);

  font-weight: 700;
}

.content {
  flex: 1;

  padding: 1rem;
}
