.right {
  display: grid;
  align-items: center;

  grid-auto-flow: column;
  gap: var(--size-spacing-mini);
}

.listPosition {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 2rem;
  height: 2rem;

  color: var(--color-premium-purple);
  border-radius: 100%;
  background-color: var(--color-premium-purpleLight);

  font-weight: 400;
}
