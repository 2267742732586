.wrapper {
  h2 {
    text-transform: uppercase;
    font-weight: 700;

    display: flex;
    justify-content: center;

    padding: 0 1rem 1rem 1rem;

    border-bottom: 1px solid var(--color-border);
  }

  p {
    padding: 1rem 0.25rem;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;

  > button {
    margin-left: 0.5rem;
  }
}
