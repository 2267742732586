.commentItem {
  margin-bottom: 1rem;
}

.comment {
  display: inline-block;

  margin-left: calc(24px + 1rem);
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;

  border-radius: 1rem;
  background-color: var(--color-background);

  font-size: 1rem;
}

.showMoreButton {
  margin-bottom: 1rem;
  margin-left: 2rem;
}
