.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.button {
  flex: 1;
  text-align: center;
  padding: 0.5rem 0;
  border: 1px solid var(--color-border);
  cursor: pointer;

  &:not(:first-of-type) {
    border-left: 0;
  }
  &:first-of-type {
    border-radius: 8px 0 0 8px;
  }
  &:last-of-type {
    border-radius: 0 8px 8px 0;
  }
  &.isActive {
    background-color: var(--color-green);
    color: var(--color-white);
  }
  &:hover:not(.isActive) {
    background-color: #f2f2f2;
  }
}
