.box {
  position: relative;
  overflow-y: auto;
}
.title {
  text-transform: uppercase;
  font-weight: 700;

  display: flex;
  justify-content: center;

  padding: 1rem;

  border-bottom: 1px solid var(--color-border);
}

.wrapper {
  padding: 1rem;
}
