.wrapper {
  padding: 0 1rem;
  padding-bottom: 0.25rem;
}

.wrapper:last-child {
  padding-bottom: 0;
}

.memberFirstName {
  flex: 1;

  margin-left: 0.5rem;
}

.noteForm {
  display: flex;
  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.reasons {
  display: flex;
  justify-content: space-evenly;

  margin: 1rem 0;
}

.reason {
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: column;

  margin: 0.25rem;
  padding: 0.5rem;

  cursor: pointer;

  border-radius: 1rem;

  &:hover {
    background-color: var(--color-border);
  }

  &.reasonSelected {
    background-color: var(--color-border);
  }

  .icon {
    font-size: 200%;
  }

  .text {
    text-align: center;
    text-transform: capitalize;

    font-size: 80%;
  }
}

.detailsButton {
  color: var(--color-gray);
  border: 1px solid var(--color-border);
  background-color: var(--color-white);
}

.reasonButtonRow {
  display: grid;

  grid-auto-flow: column;
  gap: 0.5rem;
}
