@import '../../../assets/mixins.scss';

.columnTeamBox {
  max-width: 50%;
}

.contactDetails {
  margin-bottom: 2rem;
}

.right {
  display: flex;
  align-items: center;
}

.infoRow {
  display: flex;
  align-items: center;

  svg {
    margin-right: var(--size-spacing-mini);
  }
}

.teamCode {
  position: absolute;
  z-index: 2;
  top: var(--size-spacing-small);
  right: var(--size-spacing-small);

  text-align: center;

  &__title {
    color: rgba($color: #fff, $alpha: 0.8) !important;

    @include text-caption;
  }

  &__code {
    padding: 4px 8px;

    text-transform: uppercase;

    border: 2px solid rgba($color: #fff, $alpha: 0.5);
    border-radius: 6px;

    font-weight: 500;
    line-height: 1;
  }
}
