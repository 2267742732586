.document {
  display: flex;
  flex: 1;

  cursor: pointer;
  align-items: center;

  padding: 0.5rem;
  border: 1px solid var(--color-border);
  border-radius: 0.25rem;

  span {
    font-size: 1rem;
    line-height: 0.1;
  }
}

.imagePlaceholder {
  height: 128px;
  position: relative;
  display: inline-block;
  overflow: hidden;

  img {
    height: 128px;
  }

  > .spinner {
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -1rem 0 0 -1rem;
  }
}

.spinner {
  width: 1rem;
  height: 1rem;
  background-color: #3bc47f;

  margin-right: 0.2rem;

  border-radius: 100%;
  -webkit-animation: sk-scaleout 0.8s infinite ease-in-out;
  animation: sk-scaleout 0.8s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
