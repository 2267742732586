.form {
  display: flex;
  flex-direction: column;
}

.tags {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}

.tagButton {
  background-color: #fff;
  padding: 0.5rem 1.25rem;
  border-radius: 2rem;
  border: 1px solid var(--color-border);
  flex: 0 1;
  outline: none;
  font-weight: 500;
  font-size: 120%;
  cursor: pointer;
  margin-bottom: 0.5rem;
  &:not(:last-of-type) {
    margin-right: 1rem;
  }
}

.tagSelected {
  color: white;
}

.invitesWrapper {
  display: flex;
  flex-direction: column;
}

.invites {
  display: flex;
  .space {
    width: 1rem;
  }
}

.invitedGroup {
  flex: 1;
}

.btnSelectManually {
  background-color: white;
  color: var(--color-black);
  border: 1px solid var(--color-border);
  flex: 1;
  display: flex;
  margin-top: 0.5rem;
}

.space {
  margin-bottom: 1rem;
}

.button {
  flex: 1;
  justify-content: flex-start;
  background-color: white;
  padding: 0.75rem 1rem;

  transition: all 300ms;

  border: 1px solid var(--color-border);
  border-radius: 8px;

  font-family: var(--font-stack);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1rem;

  &:hover {
    border-color: var(--color-border-dark);
    box-shadow: var(--box-shadow);
  }
}
