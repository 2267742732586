@import '../../assets/mixins.scss';

.menu {
  z-index: 9999;

  display: flex;
  overflow: hidden;
  flex-direction: column;

  width: 200px;

  border: 1px solid var(--color-border);
  border-radius: 8px;
  background-color: #fff;
  box-shadow: var(--box-shadow);

  @include text-small;
}

.menuItem {
  a,
  button {
    display: block;

    width: 100%;
    padding: var(--size-spacing-mini) var(--size-spacing-small);

    text-align: left;

    &:hover {
      cursor: pointer;

      background-color: var(--color-background);
    }
  }
}

.menuItemDestructive,
.menuItemDestructive a,
.menuItemDestructive button {
  color: var(--color-negative);
}
