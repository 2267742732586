.datetime {
  display: flex;
}

.datetimeDate {
  flex: 1;
  margin-right: 1rem;
}

.datetimeError {
  input {
    border-color: red;
  }
}

.datetimeTime {
  flex: 1;
}

.datetimeButtonWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}
