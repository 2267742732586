.reaction {
  position: relative;
  display: inline;
  margin-right: 1rem;
  padding: 0.3rem 0rem 0.25rem;
  line-height: 1;

  font-weight: 500;

  outline: none;
  cursor: pointer;
}

.count {
  position: absolute;
  bottom: -5px;
  right: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  color: green;
  font-size: 65%;
  box-shadow: var(--box-shadow);

  width: 1.25rem;
  height: 1rem;
  border: 1px solid var(--color-border);
}

.hasReacted {
  color: white;
}

.addReaction {
  display: flex;
  align-items: center;
  background-color: var(--color-border);
  border-radius: 1rem;
  padding: 0.3rem 0.4rem 0.25rem;
}

.addIcon {
  margin-right: 0.25rem;
}

.emojisRow {
  display: flex;
  margin-bottom: 1rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  .emoji {
    font-size: 200%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
