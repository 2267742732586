.dates {
  display: flex;
}

.datetimeButton {
  flex: 1;
  justify-content: flex-start;
  background-color: white;
  padding: 0.75rem 1rem;

  transition: all 300ms;

  border: 1px solid var(--color-border);
  border-radius: 8px;

  font-family: var(--font-stack);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1rem;

  &:hover {
    border-color: var(--color-border-dark);
    box-shadow: var(--box-shadow);
  }
}

.startdate {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.enddate {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.meetdate {
  margin-top: 0.5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.duration {
  margin-top: 0.5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.durationButtons {
  flex: 1;
  display: flex;
}

.durationButton {
  flex: 1;
}

.space {
  width: 1rem;
}
