@import '../../../assets/mixins.scss';

.wrapper {
  padding: var(--size-spacing-small) var(--size-spacing-small) 0 var(--size-spacing-small);
}

.top {
  display: flex;

  margin-bottom: 0.25rem;
}

.title {
  flex: 1;

  @include text-large;
}

.badgeWrapper {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
}

.other {
  margin-bottom: 0.5rem;
}

.tag {
  flex: 1;

  text-transform: uppercase;

  @include text-small;
}

.venue {
  flex: 0 0 auto;
  text-transform: uppercase;
  color: var(--color-gray);
  @include text-small;
}

.rsvpRow {
  padding: 0.5rem 0;

  background-color: var(--color-background);
}

.location {
  @include text-small;

  max-width: 70%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.location::before {
  padding-right: 0.25rem;

  content: url('./locationicon.svg');
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 2rem;
  height: 2rem;

  border-radius: 100rem;
  background-color: var(--color-border);
}
