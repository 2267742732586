.content {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.content a {
  text-decoration: underline;
  &:hover {
    color: var(--color-gray);
  }
}
