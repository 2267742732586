.item {
  cursor: pointer;
}

.title {
  margin-top: 1rem;
}

.resultWrapper {
  height: 45vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
}

.searchBar {
  display: flex;
  align-items: center;

  .searchInput {
    flex: 1;
    margin-right: 1rem;
  }
}

.emptyState {
  text-align: center;
  margin-top: 2rem;
}
