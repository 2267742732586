@import '../../assets/mixins.scss';

.memberBox {
  header {
    display: flex;
    align-items: center;

    padding: var(--size-spacing-small);
    padding-bottom: 0;

    font-weight: 500 !important;

    @include text-body;
  }
}

.singleHead {
  display: flex;
  align-items: center;

  font-weight: 500 !important;

  @include text-body;
}

.member {
  display: flex;
  align-items: center;
  flex: 1;
}

.name {
  margin-left: 0.5rem;
}

.wrapper {
  display: flex;

  padding: 1rem;
}

.col {
  width: 33%;
  margin-right: 1rem;

  header {
    @include text-small;
  }

  strong {
    font-weight: 500 !important;

    @include text-body;
  }
}
