.spinner {
  background-color: #3bc47f;
  width: 150px;
  height: 150px;

  border-radius: 100%;
  -webkit-animation: sk-scaleout 0.8s infinite ease-in-out;
  animation: sk-scaleout 0.8s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
