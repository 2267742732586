.wrapper {
  padding: calc(var(--size-spacing-large) / 2);
  padding-bottom: calc(var(--size-spacing-large) / 3);

  cursor: pointer;
}

.meta {
  display: flex;
  align-items: center;
}

.date {
  display: flex;
  align-items: center;
  flex-direction: column;

  margin-right: 0.5rem;

  .monthName {
    text-transform: uppercase;

    opacity: 0.75;

    font-size: 0.875rem;
    line-height: 1.375rem;
  }

  .day {
    font-size: 1rem;
    font-weight: 400;
    line-height: 20px;
  }
}

.maxAttendees {
  font-weight: 600;
  color: #777;
}
