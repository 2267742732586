@import '../../assets/mixins.scss';

.list {
  font-size: 24px;
  font-weight: 500;
}

.item {
  margin-bottom: var(--size-spacing-small);
  padding-bottom: var(--size-spacing-small);

  border-bottom: 1px solid var(--color-border);

  @media (min-width: 960px) {
    margin-bottom: none;
    padding-bottom: none;

    border-bottom: none;
  }

  &:last-child {
    margin-bottom: 0;

    border-bottom: none;
  }
}

.extra_items {
  display: grid;

  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: var(--size-spacing-large);
  @include text-body;

  &__item {
    a {
      display: flex;
      align-items: center;
      justify-content: center;

      color: var(--color-gray);

      svg {
        margin-right: var(--size-spacing-mini);
      }
    }
  }
}

.link {
  display: flex;
  align-items: center;

  color: var(--color-black);

  font-size: var(--font-size-huge);
  line-height: 1;

  > svg {
    margin-right: 0.75rem;
  }
}
