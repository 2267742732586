.filters {
  display: flex;

  margin-bottom: 1.5rem;

  > div {
    margin-right: 1rem;

    &:last-of-type {
      margin-right: 0;
    }
  }
}
