@import '../../assets/mixins.scss';

.wrapper {
  display: flex;
  align-items: center;

  justify-content: space-between;

  h2 {
    display: block;
    display: flex;
    align-items: center;
    flex: 1;

    margin-right: calc(-1 * var(--size-spacing-large));
    margin-bottom: calc(var(--size-spacing-large) / 1.5);
    margin-left: calc(-1 * var(--size-spacing-large));
    padding-right: var(--size-spacing-large);
    padding-left: var(--size-spacing-large);

    @include text-large;

    @media (min-width: 960px) {
      display: none;
    }
  }
}

.left {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.right {
  margin-left: 1rem;
  margin-bottom: 1rem;
}
