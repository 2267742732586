@import '../../assets/mixins.scss';

.bg {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    var(--color-background) 50%
  );
}

.grid {
  max-width: 1280px;
  min-height: 100vh;
  margin: 0 auto;

  @media (min-width: 960px) {
    display: grid;

    grid-template-columns: minmax(0, 0.6fr) minmax(0, 1.1fr) minmax(0, 0.75fr);
  }
}

.nav {
  position: sticky;
  z-index: 999;
  top: 0;
  background-color: rgba(255, 255, 255, 1);

  &__small {
    padding: var(--size-spacing-small) var(--size-spacing-small) 0;

    border-bottom: 1px solid var(--color-border);

    @include text-large;

    &__top {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__logo {
      display: inline-block;

      transform: translateY(0.4rem);
    }

    &__toggle {
      &:hover {
        cursor: pointer;
      }

      &:focus {
        outline: none;
      }
    }

    @media (min-width: 960px) {
      display: none;
    }
  }
  &__large {
    display: none;

    @media (min-width: 960px) {
      position: sticky;
      top: 0;

      display: grid;

      height: 100%;
      max-height: 100vh;
      padding: var(--size-spacing-large);

      grid-template-rows: auto 1fr auto;

      &__logo {
        display: block;

        margin-bottom: 2rem;
      }
    }
  }
}

.main {
  padding-top: var(--size-spacing-large);
  padding-bottom: var(--size-spacing-large);
  @media (min-width: 960px) {
    padding-left: var(--size-spacing-large);
  }
  background-color: var(--color-background);
}

.sidebar {
  display: none;

  @media (min-width: 960px) {
    display: block;

    padding: var(--size-spacing-large);
  }
}
