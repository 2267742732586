@import '../../../assets/mixins.scss';

.wrapper {
  &:last-of-type {
    border-bottom: 0;
  }
}

.titleWrapper {
  display: flex;
  align-items: center;

  text-transform: none;
}

.title {
  flex: 1;

  @include text-caption;
}

.groupTitle {
  margin-bottom: var(--size-spacing-small);

  text-transform: uppercase;

  color: var(--color-gray);

  @include text-caption;

  &:not(:nth-child(2)) {
    margin-top: var(--size-spacing-large);
  }
}

.orderer {
  display: flex;
  align-items: center;
}
