.button {
  flex: 1;
  justify-content: flex-start;
  background-color: white;
  padding: 0.75rem 1rem;

  transition: all 300ms;

  border: 1px solid var(--color-border);
  border-radius: 8px;

  font-family: var(--font-stack);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1rem;

  &:hover {
    border-color: var(--color-border-dark);
    box-shadow: var(--box-shadow);
  }
}

.checkboxIcon {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(41, 58, 74, 0.24);
  border-radius: 100%;
  width: 1.5rem;
  height: 1.5rem;
  overflow: hidden;
  margin-right: 0.25rem;
}

.checkboxIconChecked {
  background-color: #3bc47f;
}

.checkIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  > svg {
    margin-right: 0;
    width: 28px;
    height: 19px;
  }
}
