@import '../../assets/mixins.scss';

.flex {
  display: flex;
  align-items: center;

  margin-bottom: 0.5rem;
}

.border {
  margin-right: -1rem;
  margin-bottom: 0.5rem;
  padding-right: 1rem;

  border-bottom: 1px solid var(--color-border);
}

.left {
  margin-right: 0.5rem;
}

.middle {
  // display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.right {
  margin-left: 0.5rem;
}

.title {
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;

  font-weight: 500 !important;

  @include text-body;
}

.thin {
  margin-right: calc((var(--size-spacing-large) / 2) * -1);
  margin-bottom: 0.5rem;
  margin-left: calc((var(--size-spacing-large) / 2) * -1);
  padding: 0 calc(var(--size-spacing-large) / 4) 0.5rem;
  .title,
  small {
    font-size: 1rem;
  }
}

.listItemTitle {
  margin-right: calc((var(--size-spacing-large) / 2) * -1);
  margin-bottom: 0.5rem;
  margin-left: calc((var(--size-spacing-large) / 2) * -1);
  padding: 0 calc(var(--size-spacing-large) / 2) 0.5rem;

  border-bottom: 1px solid var(--color-border);

  @include text-caption;

  &:not(:first-child) {
    margin-top: 2rem;
  }
}
