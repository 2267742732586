@import '../../../assets/mixins.scss';

.wrapper {
  border-radius: 0.5rem;
  border: 1px solid var(--color-border);
  display: grid;
  grid-gap: 0 1rem;
  justify-content: start;
  margin-top: 1rem;
  overflow: hidden;
  padding: 0 1rem 0 0;
  & + & {
    margin-top: 0.25rem;
  }
}

.title {
  @include text-body;
  align-self: center;
  font-weight: 500;
  grid-column: 2;
  max-height: 2.8rem;
  overflow: hidden;
  word-wrap: break-word;
}

.siteName {
  grid-column: 2;
  align-self: center;
  color: #999;
}

.image {
  background-size: cover;
  background-position: center;
  border-right: 1px solid var(--color-border);
  display: flex;
  grid-row: 1 / 3;
  align-items: center;
  justify-content: center;
  height: 6rem;
  width: 6rem;
}

.spin {
  display: none;
}

.loading {
  & > *:empty {
    background-color: #ccc;
  }
  .image {
    background-color: #eee;
  }
  .spin {
    display: block;
    animation: spinner 1s infinite;
  }
  .title:empty {
    height: 1rem;
    width: 12rem;
    animation: loading-placeholder 1s infinite;
  }
  .siteName:empty {
    height: 1rem;
    width: 6rem;
    animation: loading-placeholder 1s infinite;
  }
}

@keyframes loading-placeholder {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
