.buttonRow {
  display: flex;
  justify-content: space-between;

  margin-top: 0.5rem;
  margin-bottom: -0.5rem;
  padding-top: 0.5rem;

  border-top: 1px solid var(--color-border);
}

.buttonRowAdd {
  display: flex;
}

.imageButton {
  margin-right: 0.5rem;

  color: #ffb033;
  background-color: #fff7eb;
}

.documentButton {
  color: #4f94ff;
  background-color: rgba(79, 148, 255, 0.14);
}

.composer {
  display: grid;

  gap: 0.5rem;
  grid-template-columns: auto 1fr;
}

.imageAttachmentPreviews {
  margin-top: 1rem;

  display: grid;

  grid-template-columns: repeat(3, 33%);
}

.documentAttachmentPreviews {
  display: grid;

  grid-template-rows: auto;
  gap: 0.5rem;
}
