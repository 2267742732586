.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--color-border);

  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  overflow: hidden;
}

.item {
  display: block;
  padding: 1rem;
  text-align: left;

  &:hover {
    background-color: var(--color-border-dark);
  }

  .city {
    font-size: 80%;
    opacity: 0.75;
  }
}

.close {
  position: absolute;
  top: 0.5rem;
  right: 0.25rem;
  width: 40px;
  height: 40px;
}

.startdate {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.locationSearchButton {
  flex: 1;
  justify-content: flex-start;
  background-color: white;
  padding: 0.75rem 1rem;

  transition: all 300ms;

  border: 1px solid var(--color-border);
  border-radius: 8px;

  font-family: var(--font-stack);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1rem;
  text-align: left;

  &:hover {
    border-color: var(--color-border-dark);
    box-shadow: var(--box-shadow);
  }
}

.disabledText {
  color: var(--color-gray);
}

.placeholder {
  color: var(--color-gray);
  opacity: 0.5;
}

.selectedLocation {
  display: flex;
  flex-direction: column;
  .name {
    margin-bottom: 0.25rem;
  }
  .info {
    font-size: var(--font-size-small);
  }
}
