.buttons {
  flex: 1;
  display: flex;
}

.repatingButton {
  transition: all 300ms;
  outline: none;
}

.space {
  width: 1rem;
}

.summary {
  font-size: 75%;
  margin-top: 0.5rem;
  text-align: center;
}
