.team {
  display: flex;
  flex: 1;
  align-items: center;
}

.box {
  padding: 1rem 1rem 0.5rem;
}

.freePill {
  background-color: #bbb;
  color: white;
  font-size: 0.5rem;
  font-weight: 500;
  padding: 0.1rem 0.3rem;
  border-radius: 1rem;
  letter-spacing: 0.05rem;
}

.licenseKey {
  display: flex;
}

.licenseKeyInput {
  flex: 1;
}

.licenseKeyError {
  margin: 1em 0;
  font-size: 0.875em;
  color: var(--color-negative);
}

.teamRight {
  display: flex;
  align-items: center;
}

.buttonShowForm {
  margin-right: 0.5rem;
  font-size: 80%;
  padding: 0.25rem 0.5rem;
  text-decoration: underline;
}
