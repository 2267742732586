@import '../../assets/mixins.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 36px;
  height: 36px;
  margin: 0 0.5rem;

  cursor: pointer;

  border-radius: 100%;
  outline: none;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);

  line-height: 1;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &:active {
    transform: scale(0.95);
  }

  svg {
    @include text-body;
  }
}

.spin > svg {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.text {
  width: auto;
  padding: 0 0.75rem 0 1rem;

  border-radius: 0.5rem;

  span {
    margin-right: var(--size-spacing-mini);

    white-space: nowrap;

    @include text-small;
  }
}
