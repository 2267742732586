.overlay {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  height: 100%;
  padding: var(--size-spacing-large);

  background-color: rgba(0, 0, 0, 0.75);
}
.content {
  // position: absolute;
  // top: 2rem;
  @media (max-width: 960px) {
    width: 90%;
  }

  @media (min-width: 961px) {
    min-width: 640px;
    max-width: 90vw;
  }
  max-height: 100%;

  display: flex;
  flex-direction: column;
}
