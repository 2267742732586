.gallery {
  display: grid;

  margin: 1rem -1rem;

  gap: 1px;
}

.image {
  overflow: hidden;

  height: 0;
  padding-top: 60%;

  background-color: var(--color-black);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  &:hover {
    cursor: pointer;
  }
}

.overlay {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.75);
}

.overlayImage {
  width: 80%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    z-index: 1001;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
}

.chevron {
  z-index: 1002;
  height: 100px;

  transition: all 300ms ease;

  font-size: 4rem;

  &:hover {
    cursor: pointer;

    &.chevronPrev {
      transform: translateX(0.25rem);
    }
    &.chevronNext {
      transform: translateX(-0.25rem);
    }
  }
}

.tapToClose {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}
