.wrapper {
  @media (min-width: 960px) {
    display: grid;

    height: 100vh;

    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.logo {
  margin-bottom: var(--size-spacing-large);

  fill: var(--color-green);
}

.main {
  margin: var(--size-spacing-small);

  @media (min-width: 960px) {
    display: grid;
    align-items: center;
    justify-content: center;

    margin-top: unset;
  }
}

.content {
  @media (min-width: 960px) {
    width: 22rem;
  }
}

.aside {
  display: none;

  @media (min-width: 960px) {
    display: grid;
    align-content: flex-end;

    padding: var(--size-spacing-large);

    background-color: var(--color-purple);
    background-image: url(./banner-bg.svg);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;

    grid-template-columns: 1fr auto;
  }
}

.asideContent {
  color: var(--color-white);

  h2 {
    margin-bottom: 1rem;

    font-size: 2rem;

    & + p {
      max-width: 30rem;
      margin-bottom: var(--size-spacing-large);
    }
  }

  svg {
    width: auto;
    height: 40px;
    margin-right: calc(var(--size-spacing-large) / 2);
  }

  a {
    text-decoration: underline;

    color: var(--color-white);

    &:hover {
      text-decoration: none;
    }
  }
}

.title {
  margin-bottom: 1rem;

  font-size: 2rem;
  font-style: italic;
  line-height: 2.3rem;

  span {
    display: block;

    letter-spacing: 1px;
    text-transform: capitalize;

    color: var(--color-green);

    font-weight: 600;
    font-style: normal;
  }

  & + p,
  & + form > p {
    margin-bottom: calc(var(--size-spacing-large) * 2);
  }
}

.buttons > * {
  margin-bottom: 0.5rem;
  justify-content: center;

  svg {
    margin-right: 0.5rem;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
}

.buttonPhone,
.buttonEmail {
  background-color: #385c7c;
}
.buttonFacebook {
  background-color: #0563a3;
}
.buttonApple {
  background-color: #131313;
}
