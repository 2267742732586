.wrapper {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  text-transform: uppercase;

  border-radius: 100%;

  small {
    color: #fff;

    font-weight: 500;
  }
}
