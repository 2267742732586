@import '../../assets/mixins.scss';

.badge {
  display: flex;
  align-items: center;

  padding: 0.3rem 0.4rem 0.25rem;

  color: var(--color-gray);
  border: 1px solid;

  @include text-small;
}

.solid {
  border-color: var(--color-border);
  border-radius: 2rem;
  background-color: var(--color-border);
}

.line {
  border-color: var(--color-border);
  border-radius: 6px;
}

.negative {
  color: var(--color-white);
  border-color: var(--color-negative);
  border-radius: 2rem;
  background-color: var(--color-negative);
}
