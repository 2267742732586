@import './reset.scss';
@import './mixins.scss';

:root {
  --box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);

  --size-spacing-mini: 0.5rem;
  --size-spacing-small: 1rem;
  --size-spacing-large: 2rem;
  --color-white: #fff;
  --color-background: #f4f9fb;
  --color-green: #3bc47f;
  --color-purple: #2e2078;
  --color-black: #212223;
  --color-gray-dark: #444;
  --color-gray: #5c687b;
  --color-border: rgb(240, 240, 240);
  --color-border-dark: rgb(200, 200, 200);
  --color-negative: #ff7c7c;
  --color-negative-light: #ffdede;
  --color-positive: #88d064;
  --color-positive-light: #e1f3d8;
  --color-info: #009eff;
  --color-info-light: #bfe6ff;
  --color-premium-purpleLight: #f7f3fc;
  --color-premium-purple: #55469f;
  --color-premium-purpleDark: #2f2178;
  --color-premium-purpleMid: #41328c;
  --color-premium-orange: #f8cd76;
  --font-stack: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-size-small: 0.8rem;
  --font-size-normal: 1rem;
  --font-size-large: 1.5rem;
}

// apply a natural box layout model to all elements, but allowing components to change
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  font-size: 100%;

  font-kerning: normal; // Set the default for kerning
  font-synthesis: none; // Don't allow the font to create sythetic bold/italic
  text-rendering: optimizeLegibility; // Improve kerning pairs. Caution: Webkit gets funny with this sometimes
  -webkit-font-smoothing: antialiased; // Improve (or in some cases screw with) safari's legibility somewhat
}

body {
  color: var(--color-black);
  background-color: var(--color-background);

  font-family: var(--font-stack);

  @include text-body;
}

a,
a:hover {
  color: var(--color-black);
}

a {
  text-decoration: none;
}

small {
  @include text-small;
}

strong {
  font-weight: 500;
}

img {
  display: block;
}

.screen-reader-text {
  position: absolute;

  overflow: hidden;
  clip: rect(0 0 0 0);

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;

  border: 0;
}

button {
  @include reset-button;
}

input,
select,
textarea {
  width: 100%;
  padding: 0.75rem 1rem;

  transition: all 300ms;

  border: 1px solid var(--color-border);
  border-radius: 8px;

  font-family: var(--font-stack);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1rem;

  &:hover {
    border-color: var(--color-border-dark);
    box-shadow: var(--box-shadow);
  }

  &:focus {
    border: 1px solid var(--color-green);
    outline: none;
    box-shadow: var(--box-shadow);
  }
}

input:focus::placeholder {
  color: transparent;
}

select {
  font-family: var(--font-stack);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1rem;
}

hr {
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-border);
}
