.wrapper {
  display: flex;
}

.input {
  position: relative;
  top: 0;
  left: 0;

  visibility: hidden;

  width: 0;
  height: 0;
}

.input:checked + .label .button {
  left: calc(100% - 2px);

  transform: translateX(-100%);
}

.input:checked + .label {
  background-color: var(--color-green);
}

.label {
  position: relative;

  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;

  width: 3.5rem;
  height: 1.8rem;

  cursor: pointer;
  transition: background-color 0.2s;

  border-radius: 100px;
  background: var(--color-border);
}

.label:active .button {
  width: 2.1rem;
}

.button {
  position: absolute;
  top: 2px;
  left: 2px;

  width: calc(1.8rem - 4px);
  height: calc(1.8rem - 4px);

  content: '';
  transition: 0.2s;

  border-radius: 2rem;
  background: #fff;
  box-shadow: var(--box-shadow);
}
