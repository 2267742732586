.wrapper {
  display: flex;
  flex-direction: row-reverse;

  padding-bottom: var(--size-spacing-large); // var(--size-spacing-large) 0;
}

.circleWrapper {
  display: flex;

  div:first-child {
    margin-right: calc(var(--size-spacing-large) / 4);
  }
}
