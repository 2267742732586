.error {
  position: fixed;
  z-index: 999;
  top: 5px;
  right: 15%;
  left: 15%;

  display: flex;
  align-items: center;

  padding: 1rem;

  color: var(--color-white);
  border-radius: 0.5rem;
  background-color: var(--color-negative);

  > p {
    flex: 1;

    text-align: center;
  }

  .btnClose {
    width: 30px;
    height: 30px;

    cursor: pointer;

    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 10rem;

    font-size: 120%;
    font-weight: 400;
  }
}
