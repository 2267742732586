@import '../../assets/mixins.scss';

.head {
  display: flex;
  align-items: center;

  padding: 1rem;

  border-bottom: 1px solid var(--color-border);

  @include text-large;
}

.icon {
  margin-right: 0.5rem;

  font-size: 28px;
}

.footer {
  display: flex;

  padding: 0.6rem 1rem;

  background-color: var(--color-background);

  .linkToPast {
    display: block;
    flex: 1;

    font-weight: 500;
  }
}

.wrapper {
  display: flex;

  padding: 1rem;
}

.col {
  flex: 1;

  margin-right: 1rem;

  header {
    @include text-small;
  }

  .large {
    @include text-large;
  }
}

.expanded {
  display: flex;
  align-items: center;
  flex-direction: row;

  > strong,
  > div:first-child {
    flex: 1;
  }

  .bar {
    width: 33%;
  }
}

.shrunken {
  display: flex;
  flex-direction: column;
}
