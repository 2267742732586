.footer {
  display: flex;
  align-items: center;
}

.reactions {
  display: flex;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
}

.seenByBadge {
  cursor: pointer;
}

.seenByWrapper {
  margin-bottom: 1rem;
  padding: 1rem;

  border-radius: 0.5rem;
  background-color: var(--color-background);
}

.seenByReactions {
  display: flex;
}

.grid {
  @media (min-width: 960px) {
    display: grid;

    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: var(--size-spacing-small);
  }
}
