.text {
  margin-top: calc(var(--size-spacing-large) / 4);
  margin-bottom: calc(var(--size-spacing-large) / 4);

  > p {
    margin-bottom: 0.25rem;
  }
}

.footer {
  display: flex;
  align-items: center;
}

.reactions {
  flex: 1;
}

.seenByReactions {
  display: flex;
}

.attachments {
  display: flex;
  flex-direction: column;
}

.attachmentType {
  flex: 1;
}

.comment {
  display: flex;
  flex-direction: column;

  margin-bottom: 1rem;
}

.commentMeta {
  display: flex;
  align-items: center;
}

.commentProfileImage {
  margin-right: 0.5rem;
}

.commentText {
  display: inline-block;

  margin-left: calc(24px + 0.25rem);
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;

  border-radius: 1rem;
  background-color: #fff;
}

.commentForm {
  display: flex;

  // margin-right: -1rem;
  // margin-bottom: -1rem;
  // margin-left: -1rem;
}

.commentFormInput {
  // border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  // border-bottom-left-radius: 1rem;

  font-size: 1rem;
}

.commentFormButton {
  border-top-left-radius: 0px;
  // border-top-right-radius: 0px;
  // border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 0px;

  font-size: 1rem;
}
